"use client";

import { type User } from "lucia";
import { useRouter } from "next/navigation";
import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useRef } from "react";
import { createStore, useStore } from "zustand";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog";
import useKeyboardShortcut, {
  KeyboardShortcutBinding,
} from "~/lib/hooks/use-keyboard-shortcut";
import { logger } from "~/lib/logger";
import { ActionName } from "~/lib/utils";

import { CMDK } from "./cmdk";
import { createDefaultActions } from "./createActions";
import { type ActionRecord } from "./types";
import {
  type CommandState,
  type CommandStore,
  type CommandStoreContext,
} from "./types";

export const createCommandStore = (ctx: CommandStoreContext) => {
  const actions = createDefaultActions(ctx);
  return createStore<CommandState>()((set, get) => ({
    actions,
    triggerAction: async (actionName) => {
      if (get().runningAction) {
        return;
      }
      const action = get().actions[actionName];
      if (!action) {
        logger.warn(`No action found for ${actionName}`);
        return;
      }
      if (action.type === "handler") {
        const blocking = action.blocking;
        set({ runningAction: actionName, blocking });
        await action.handler(ctx);
        set({ blocking: undefined, runningAction: undefined });
      }
      if (action.type === "redirect") {
        ctx.router.push(action.url(ctx));
      }
      if (action.type === "render") {
        set({ runningAction: actionName });
      }
    },
    close: () => {
      set({ runningAction: undefined });
    },
    addActions: (newActions) => {
      const actionNames = Object.keys(newActions);
      for (const name of actionNames) {
        const res = ActionName.safeParse(name);
        if (!res.success) {
          logger.warn("Invalid action name", name, res);
          delete newActions[name];
        }
      }
      set((state) => ({
        actions: {
          ...state.actions,
          ...newActions,
        },
      }));
      return () => {
        set(() => {
          const actions = { ...get().actions };
          for (const actionName of actionNames) {
            delete actions[actionName];
          }
          return {
            actions,
          };
        });
      };
    },
    ctx,
  }));
};

export const CommandContext = createContext<CommandStore | null>(null);

export function Provider({
  children,
  user,
}: {
  children: ReactNode;
  user?: User;
}) {
  const router = useRouter();
  const store = useRef(createCommandStore({ user, router })).current;
  const [open, setOpen] = useState(false);
  useKeyboardShortcut(
    (e) => {
      e.preventDefault();
      setOpen((open) => !open);
    },
    {
      metaKey: true,
      code: "KeyK",
    },
  );
  return (
    <CommandContext.Provider value={store}>
      <CMDK open={open} setOpen={setOpen} />
      <BlockerDialog />
      <RenderComponent />
      <KeyboardShortcutBindings />
      {children}
    </CommandContext.Provider>
  );
}

function RenderComponent() {
  const Store = useCommandStore();
  const { actions, runningAction, close } = useStore(Store, (s) => ({
    actions: s.actions,
    runningAction: s.runningAction,
    close: s.close,
  }));
  const action = runningAction && actions[runningAction];
  if (!action) {
    return null;
  }
  const RenderComponent = (action.type === "render" && action.render) ?? null;
  return (
    <Dialog
      open={!!action && !!RenderComponent}
      onOpenChange={(open) => {
        if (!open) {
          close();
        }
      }}
    >
      <DialogContent className="p-0 sm:max-w-[425px]">
        <DialogHeader>{RenderComponent}</DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export function BlockerDialog() {
  const Store = useCommandStore();
  const blocking = useStore(Store, (s) => s.blocking);
  if (!blocking) return null;
  return (
    <Dialog open={true} modal={false}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{blocking.title}</DialogTitle>
          <DialogDescription>
            <blocking.Icon className="animate-spin" />
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export function useCommandStore() {
  const Store = useContext(CommandContext);
  if (!Store) throw new Error("Missing CommandContext.Provider in the tree");
  return Store;
}

function KeyboardShortcutBindings() {
  const Store = useCommandStore();
  const { actions, triggerAction } = useStore(Store, (s) => ({
    actions: s.actions,
    triggerAction: s.triggerAction,
  }));

  const shortcutitems = Object.entries(actions).filter(
    ([_name, action]) => action.shortcut,
  );
  return (
    <>
      {shortcutitems.map(([key, item]) => (
        <KeyboardShortcutBinding
          key={key}
          shortcutAction={() => triggerAction(key)}
          config={item.shortcut!}
        />
      ))}
    </>
  );
}

export function BindActions({ actions }: { actions: ActionRecord }) {
  const Store = useCommandStore();
  const { addActions } = useStore(Store, (s) => ({
    addActions: s.addActions,
  }));
  useEffect(() => {
    return addActions(actions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);
  return null;
}

export function useCommanderClose() {
  const Store = useCommandStore();
  const close = useStore(Store, (s) => s.close);
  return close;
}

export function useTriggerAction() {
  const Store = useCommandStore();
  const triggerAction = useStore(Store, (s) => s.triggerAction);
  return triggerAction;
}
