"use client";

import { HomeIcon } from "lucide-react";

import { type ActionRecord, type CommandStoreContext } from "./types";

export function createDefaultActions(_ctx: CommandStoreContext): ActionRecord {
  return {
    "test.home": {
      Icon: HomeIcon,
      type: "redirect",
      url({ user }) {
        if (user) {
          if (user.role === "STUDENT") {
            return "/student";
          }
          if (user.role === "TEACHER") {
            return "/larare";
          }
        }
        return "/";
      },
      command: {
        title: "Mina grupper",
      },
      shortcut: {
        metaKey: true,
        code: "KeyH",
        description: "⌘H",
      },
    },
  };
}
