"use client";

import { useCallback, useEffect } from "react";

type OptionalConfig = Pick<
  KeyboardEvent,
  "altKey" | "ctrlKey" | "shiftKey" | "metaKey"
>;

export interface ShortcutConfig extends Partial<OptionalConfig> {
  code: KeyboardEvent["code"];
  shortcutTarget?: HTMLElement;
}

type ShortcutAction = (e: KeyboardEvent) => void;

export default function useKeyboardShortcut(
  shortcutAction: ShortcutAction,
  config: ShortcutConfig,
) {
  let targetElement: HTMLElement | undefined = config.shortcutTarget;
  if (!targetElement) {
    if (typeof document === "undefined") {
      // throw new Error("No document found");
    } else {
      targetElement = document.body;
    }
  }
  // const targetElement = config.shortcutTarget ?? document;

  const eventHandler = useCallback(
    (e: KeyboardEvent) => {
      const { code, ctrlKey, altKey, shiftKey, metaKey } = e;
      if (config.code !== code) return;
      if (config.ctrlKey && !ctrlKey) return;
      if (config.shiftKey && !shiftKey) return;
      if (config.altKey && !altKey) return;
      if (config.metaKey && !metaKey) return;

      shortcutAction(e);
    },
    [shortcutAction, config],
  );

  useEffect(() => {
    targetElement?.addEventListener("keydown", eventHandler);
    return () => targetElement?.removeEventListener("keydown", eventHandler);
  }, [targetElement, eventHandler]);
}

export function KeyboardShortcutBinding({
  shortcutAction,
  config,
}: {
  shortcutAction: ShortcutAction;
  config: ShortcutConfig;
}) {
  useKeyboardShortcut((e) => {
    e.preventDefault();
    shortcutAction(e);
  }, config);
  return null;
}
