"use client";

import { type LucideIcon } from "lucide-react";
import { useRouter } from "next/navigation";
import React from "react";
import { useStore } from "zustand";

import { Translation } from "../translations/component";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandShortcut,
} from "../ui/command";
import { useCommandStore } from "./provider";
import { type Action } from "./types";

/* eslint-disable @typescript-eslint/no-empty-function */

export function CMDK(
  { open, setOpen } = { open: false, setOpen: (_open: boolean) => {} },
) {
  const [inputValue, setInputValue] = React.useState("");
  const router = useRouter();

  const Store = useCommandStore();
  const { actions, triggerAction } = useStore(Store, (s) => ({
    actions: s.actions,
    triggerAction: s.triggerAction,
  }));

  const actionsArray = Object.entries(actions);
  const commandActions = actionsArray
    .map(([key, action]) => {
      return {
        ...action,
        key,
      };
    })
    .filter((action) => !!action.command);

  const redirectGroupActions = actionsArray.filter(
    ([_key, action]) => action.type === "redirect-group",
  );

  type ActionWithKey = Action & { key: string };

  const actionGroups: Record<string, ActionWithKey[]> = {
    Genvägar: [],
  };
  Object.entries(actions).forEach(([key, action]) => {
    if (!action.command) {
      return;
    }
    const groupTitle = action.command.groupTitle ?? "Genvägar";
    if (!actionGroups[groupTitle]) {
      actionGroups[groupTitle] = [] as ActionWithKey[];
    }
    actionGroups[groupTitle]!.push({
      ...action,
      key,
    });
  });

  return (
    <CommandDialog open={open} onOpenChange={setOpen}>
      <CommandInput
        value={inputValue}
        onValueChange={(value) => setInputValue(value)}
        placeholder="Type a command or search..."
      />
      <CommandList>
        <CommandEmpty>
          <Translation id="common.no-results">Inga resultat</Translation>.
        </CommandEmpty>
        {Object.entries(actionGroups).map(([groupTitle, actions]) => {
          return (
            <CommandGroup key={groupTitle} heading={groupTitle}>
              {actions
                .filter((action) =>
                  action
                    .command!.title.toLowerCase()
                    .includes(inputValue.toLowerCase()),
                )
                .map((action) => {
                  return (
                    <Item
                      key={action.key}
                      shortcut={action.shortcut?.description}
                      onSelect={() => {
                        setOpen(false);
                        triggerAction(action.key);
                      }}
                      Icon={action.Icon}
                      label={action.command!.title}
                    />
                  );
                })}
              <CommandEmpty>Ingen action hittades</CommandEmpty>
            </CommandGroup>
          );
        })}
        {/* <CommandGroup heading="Genvägar">
          {commandActions
            .filter((action) =>
              action
                .command!.title.toLowerCase()
                .includes(inputValue.toLowerCase()),
            )
            .map((action) => {
              return (
                <Item
                  key={action.key}
                  shortcut={action.shortcut?.description}
                  onSelect={() => {
                    setOpen(false);
                    triggerAction(action.key);
                  }}
                >
                  {action.Icon && <action.Icon className="mr-2 h-4 w-4" />}
                  {action.command!.title}
                </Item>
              );
            })}
          <CommandEmpty>Ingen action hittades</CommandEmpty>
        </CommandGroup> */}
        {redirectGroupActions.map(([key, action]) => {
          if (action.type !== "redirect-group") {
            return null;
          }
          // if (!inputValue.startsWith(action.prefix)) {
          //   return null;
          // }
          const cleanInput = inputValue.replace(action.prefix, "");
          const hits = action.items.filter(
            (item) =>
              item.label.toLowerCase().indexOf(cleanInput.toLowerCase()) > -1,
          );
          return (
            <CommandGroup
              key={key}
              heading={action.groupByTitle}
              prefix={action.prefix}
              value={cleanInput}
            >
              <CommandEmpty>
                Inga grupper hittades med namnet {cleanInput}
              </CommandEmpty>
              {hits.map((item) => {
                return (
                  <Item
                    key={item.label}
                    onSelect={() => {
                      setOpen(false);
                      router.push(item.url);
                    }}
                    label={item.label}
                  />
                );
              })}
            </CommandGroup>
          );
        })}
      </CommandList>
    </CommandDialog>
  );
}

function Item({
  shortcut,
  onSelect = () => {},
  label,
  Icon,
}: {
  shortcut?: string;
  onSelect?: (value: string) => void;
  label: string;
  Icon?: LucideIcon;
}) {
  return (
    <CommandItem onSelect={onSelect} value={label}>
      {Icon && <Icon className="mr-2 h-4 w-4" />}
      {label}
      {shortcut && <CommandShortcut>{shortcut}</CommandShortcut>}
    </CommandItem>
  );
}
